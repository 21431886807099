
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { errorHandler, formatDate } from 'utils/helpers';
import { slugToName } from 'components/pipelines/helpers';
import { Box, Card, CardBody, CardHeader, Flex, Heading, Text } from '@chakra-ui/react';
import Loading from 'components/ui/Loading';
import { usePipelineAPIs } from 'api/Pipelines/usePipelineAPIs';
import MainPanelError from 'components/ui/MainPanelError';
import { ProcessingCategory, ProcessingStatus } from 'models/Pipelines';
import { PipelineContext } from './PipelineView';
import { BsInfoCircle } from 'react-icons/bs';
import CustomDatePicker from 'components/pipelines/CustomDatePicker';
import StatusBreakdowns from 'components/pipelines/category/statusBreakdowns';
import CategoryTable from 'components/pipelines/category/CategoryTable';

function Category() {
    // Hooks
    const { getProcessingCategory, getCategoryStatus } = usePipelineAPIs()
    const { pipelineSlug, categorySlug } = useParams();

    const { selectedDate, setSelectedDate } = useContext(PipelineContext);

    const { isLoading, error, data } = useQuery<ProcessingCategory>({
        queryKey: [pipelineSlug, categorySlug],
        queryFn: () => getProcessingCategory({ pipelineSlug, categorySlug }),
        staleTime: 2 * 1000 * 60,
        enabled: !!pipelineSlug && !!categorySlug,
        retry: 1
    });

    const { isLoading: statusLoading, error: statusError, data: status } = useQuery<ProcessingStatus>({
        queryKey: [pipelineSlug, categorySlug, 'status', selectedDate],
        queryFn: () => getCategoryStatus({ pipelineSlug, categorySlug, date: selectedDate }),
        staleTime: 2 * 1000 * 60,
        enabled: !!pipelineSlug && !!categorySlug,
        retry: 1
    });

    return (
        <Box>
            {error ? (
                <MainPanelError
                    errorMessage={errorHandler(error).message}
                />
            ) : isLoading ? (
                <Box w={'100%'} height={'100%'}>
                    <Loading message={`loading "${slugToName(pipelineSlug)}" data pipeline...`} />
                </Box>
            ) : (
                data && (
                    <Box>
                        {/* Heading */}
                        <Card mb={6} variant="outline">
                            <CardBody>
                                <Box>
                                    <Heading size="md" mb={2}>
                                        {data?.description}
                                    </Heading>
                                    <Text fontSize={'xs'} color={'gray.600'} fontStyle={'italic'}>
                                        Last updated: {formatDate(new Date(data.updated_at))}
                                    </Text>
                                </Box>
                            </CardBody>
                        </Card>

                        {/* Category Statuses Graph */}
                        <Card mb={6} variant="outline">
                            <CardHeader>
                                <Heading size="md">Category Statuses Overview</Heading>
                            </CardHeader>
                            <CardBody
                                bg={'gray.50'}
                                borderTop={'1px'}
                                borderColor={'gray.100'}
                            >
                                <CategoryTable />
                            </CardBody>
                        </Card>

                        <Flex justify="flex-end" mb={4}>
                            <Flex gap={2} align={'center'} justify={'center'}>
                                <Text color="blue.600" fontSize={'sm'}>select a date</Text>
                                <CustomDatePicker
                                    selectedDate={selectedDate}
                                    onChange={setSelectedDate}
                                />
                            </Flex>
                        </Flex>

                        {/* Category Status Details */}
                        <Card variant="outline">
                            <CardHeader>
                                <Heading size="md">Category Details</Heading>
                            </CardHeader>
                            <CardBody
                                borderTop={'1px'}
                                borderColor={'gray.100'}
                                p={statusLoading || !status ? 0 : 4}
                            >
                                {statusLoading ? (
                                    <Flex justify={'center'} align={'center'} py={12}>
                                        <Loading message="Loading category status..." />
                                    </Flex>
                                ) : statusError ? (
                                    <MainPanelError
                                        errorMessage={errorHandler(statusError).message}
                                    />
                                ) : status?.breakdowns ? (
                                    <StatusBreakdowns data={status.breakdowns} />
                                ) : (
                                    <Flex
                                        direction={'column'}
                                        align={'center'}
                                        justify={'center'}
                                        py={12}
                                        bg={'gray.50'}
                                        color={'gray.600'}
                                    >
                                        <BsInfoCircle size={32} style={{ marginBottom: '1rem' }} />
                                        <Text fontSize={'lg'} fontWeight={'medium'}>
                                            No Details Available
                                        </Text>
                                        <Text fontSize={'sm'} mt={2}>
                                            No category status details are available for the selected date
                                        </Text>
                                    </Flex>
                                )}
                            </CardBody>
                        </Card>
                    </Box>
                )
            )}
        </Box>
    )
}

export default Category