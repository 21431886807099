import { Flex, SimpleGrid } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query";
import { usePipelineAPIs } from "api/Pipelines/usePipelineAPIs"
import DataPipelineCard from "components/pipelines/DataPipelineCard";
import Loading from "components/ui/Loading";
import MainPanelError from "components/ui/MainPanelError";
import { DataPipeline } from "models/Pipelines";
import { errorHandler } from "utils/helpers";

const Pipelines = () => {
    // Hooks
    const { getAllPipelines } = usePipelineAPIs()

    const { isLoading, error, data } = useQuery({
        queryKey: ["pipelines"],
        queryFn: getAllPipelines,
        staleTime: 2 * 1000 * 60,
    });

    if (error)
        return (
            <Flex w={"100%"} h={"100%"}>
                <MainPanelError errorMessage={errorHandler(error).message} />
            </Flex>
        );

    if (isLoading)
        return (
            <Flex
                direction={"column"}
                h={"100%"}
                w={"100%"}
                align={"center"}
                justify={"center"}
            >
                <Loading message="Loading pipelines.." />
            </Flex>
        );

    return (
        <SimpleGrid
            templateColumns={"repeat(auto-fit, 270px)"}
            gap={"24px"}
            mt={"20px"}
            p={1}
        >
            {data && data.map((pipeline: DataPipeline, index: number) => (
                <DataPipelineCard
                    key={index}
                    pipeline={pipeline}
                />
            ))}
        </SimpleGrid>
    )
}

export default Pipelines