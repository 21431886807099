import { useNavigate } from "react-router-dom";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Icon,
    Text,
} from "@chakra-ui/react";

import { ChevronRightIcon } from "@chakra-ui/icons";
import { BsDatabaseFillGear } from "react-icons/bs";
import { slugToName } from "./helpers";

interface PipelinesBreadcrumbProps {
    pipeline: string;
    category: string;
}

function PipelinesBreadcrumb({ pipeline, category }: PipelinesBreadcrumbProps) {
    // Navigation
    const navigate = useNavigate();

    // Handlers
    const handleGoToIndex = () => pipeline && navigate("/pipelines");
    const handleGoToOpenPipeline = () => {
        if (pipeline) navigate(`/pipelines/${pipeline}`);
    }

    return (
        <Breadcrumb
            spacing={1}
            separator={<ChevronRightIcon color="gray.500" />}
            fontSize={"14px"}
            w={"600px"}
        >
            {/* Index */}
            <BreadcrumbItem>
                <BreadcrumbLink
                    pointerEvents={pipeline ? "auto" : "none"}
                    onClick={handleGoToIndex}
                    lineHeight={1}
                    p={1.5}>
                    <Icon
                        as={BsDatabaseFillGear}
                        boxSize={"18px"}
                        color={pipeline ? "primary.200" : "primary.300"}
                    />
                </BreadcrumbLink>
            </BreadcrumbItem>

            {/* pipeline */}
            {pipeline && (
                <BreadcrumbItem
                    onClick={handleGoToOpenPipeline}
                    color={!category ? "primary.400" : "gray.500"}
                >
                    <BreadcrumbLink
                        fontWeight={category ? "400" : "500"}
                        pointerEvents={category ? "auto" : "none"}
                        textTransform={"capitalize"}
                        _hover={{
                            textDecoration: category ? "underline" : "none",
                        }}
                    >
                        {slugToName(pipeline)}
                    </BreadcrumbLink>
                </BreadcrumbItem>
            )}

            {/* Category */}
            {category && (
                <BreadcrumbItem
                    color={"primary.400"}
                    whiteSpace={"nowrap"}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                >
                    <BreadcrumbLink
                        fontWeight={"500"}
                        pointerEvents={"none"}
                        _hover={{ textDecoration: "none" }}
                        w={"100%"}
                    >
                        <Text
                            whiteSpace={"nowrap"}
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                            textTransform={"capitalize"}
                        >
                            {slugToName(category)}
                        </Text>
                    </BreadcrumbLink>
                </BreadcrumbItem>
            )}
        </Breadcrumb>
    );
}

export default PipelinesBreadcrumb;
