import { createContext } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Flex, Heading, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import PipelinesBreadcrumb from "components/pipelines/PipelinesBreadcrumb";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import { maxHeight } from "utils/responsive";

export const PipelinesContext = createContext({});

export default function PipelinesView() {

    // hooks 
    const { pipelineSlug, categorySlug } = useParams();

    // Theme
    const headingColor = useColorModeValue('secondary.800', 'neutral.400');

    // Styles
    const OverviewHeight = useBreakpointValue(maxHeight);
    const OverviewStyle = {
        height: `calc(${OverviewHeight} - 140px)`,
        width: '100%',
    };


    return (
        <Flex
            h={"100%"}
            direction="column"
            mx="auto"
            gap={6}
            overflow={"hidden"}
            maxW={"1200px"}
            w={"95%"}
        >
            <Heading
                fontSize={[null, null, null, '20px', '24px', '28px']}
                color={headingColor}
                fontWeight={'regular'}
                lineHeight={1}
            >
                Data pipelines
            </Heading>

            {/* Breadcrumb */}
            <PipelinesBreadcrumb
                pipeline={pipelineSlug ?? ""}
                category={categorySlug ?? ""}
            />
            <CustomScrollBar style={OverviewStyle}>
                <PipelinesContext.Provider value={{}}>
                    <Outlet context={{}} />
                </PipelinesContext.Provider>
            </CustomScrollBar>
        </Flex>
    );
}
