import { Link as RouterLink, useLocation } from "react-router-dom";
import { Flex, Icon, Link, Tooltip } from "@chakra-ui/react";

import { ItemProps } from ".";
import { useMemo } from "react";

export interface NavItemProps {
  item: ItemProps;
}

export default function NavItem({ item }: NavItemProps) {
  const { link, tooltip, icon, activeIcon } = item;

  // Hooks
  const location = useLocation();

  // active route
  const isActive = useMemo(
    () => {
      const isHome = link === '/dashboard' && location?.pathname === '/'

      return isHome || location?.pathname?.includes(link)
    },
    [location, link]
  );

  return (
    <Link
      as={RouterLink}
      to={link}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      color={
        isActive ? "highlight.primary" : "gray.500"
      }
    >
      <Tooltip
        label={tooltip}
        placement={"right"}
        bg={"gray.900"}
        color={"gray.100"}
        hasArrow
        py={2}
        px={3}
        m={2}
        maxW={[null, null, null, "200px", "220px", "240px"]}
        fontSize={"12px"}
        boxShadow={"none"}
        arrowSize={8}
      >
        <Flex
          align={"center"}
          gap={2}
        >
          <Icon
            as={isActive ? activeIcon : icon}
            boxSize={[null, null, null, "20px", "22px", "24px"]}
            h={"100%"}
          />
        </Flex>
      </Tooltip>
    </Link>
  );
}
