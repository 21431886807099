import { useState } from "react";
import { Box, Flex, Icon, Text, useTheme } from "@chakra-ui/react";
import { linesStyles, tilesStyles } from "./helpers";

import Tile from "./Tile";
import Line from "./Line";
import Shine from "./Shine";

import { GetGradients } from "utils/gradients";
import { hexToRgba } from "utils/helpers";
import * as BiIcons from "react-icons/bi";
import { DataPipeline } from "models/Pipelines";
import { useNavigate } from "react-router-dom";

interface DataPipelineCardProps {
    pipeline: DataPipeline;
}

function DataPipelineCard({ pipeline }: DataPipelineCardProps) {
    // Hooks
    const navigate = useNavigate()

    //State
    const [hovered, setHovered] = useState(false);
    const { icon, slug, name, description } = pipeline;

    // Theme
    const { colors } = useTheme();
    const { rGradient } = GetGradients();
    const colorMode = localStorage.getItem("chakra-ui-color-mode");
    const isLight = colorMode === "light";


    const getIcon = (icon: string | undefined) => {
        return icon ? BiIcons[icon as keyof typeof BiIcons] : BiIcons['BiData'];
    };


    const handlePipelineClick = () => navigate(`/pipelines/${slug}`);

    return (
        <Box
            position={"relative"}
            cursor={"pointer"}
            bg={"background"}
            boxShadow={"md"}
            borderColor={isLight ? "gray.200" : "gray.300"}
            borderWidth={1}
            px={4}
            pt={14}
            pb={8}
            borderRadius={"12px"}
            transition={"all 0.3s"}
            _hover={{ boxShadow: "lg", borderColor: "gray.300" }}
            _before={{
                content: "''",
                position: "absolute",
                inset: "0",
                borderRadius: "12px",
                bg: "transparent",
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={handlePipelineClick}
        >
            <Flex direction={"column"} gap={6}>
                <Box
                    as="span"
                    zIndex="2"
                    position="relative"
                    display="table"
                    p={2}
                    w={"fit-content"}
                    _after={{
                        content: "''",
                        position: "absolute",
                        inset: "0",
                        borderRadius: "50%",
                        bgGradient: rGradient,
                        border: `1px solid ${hovered ? colors.gray[300] : "transparent"}`,
                        transition: "all 0.25s",
                    }}
                >
                    <Icon
                        position={"relative"}
                        display={"block"}
                        as={getIcon(icon)}
                        boxSize={{ lg: 5, xl: 6 }}
                        zIndex={1}
                        p={0.5}
                        color={hexToRgba(colors.black, 0.6)}
                    />
                </Box>

                <Flex direction={"column"} gap={1}>
                    <Text
                        fontWeight={"600"}
                        fontSize={{ lg: "14px", xl: "16px" }}
                        color={"gray.700"}
                        fontFamily={"Poppins, sans-serif"}
                    >
                        {name}
                    </Text>

                    <Text
                        fontSize={{ lg: "12px", xl: "14px" }}
                        lineHeight={"1.4"}
                        color={"gray.700"}
                        opacity={0.8}
                    >
                        {description}
                    </Text>
                </Flex>
            </Flex>

            <Shine hovered={hovered} />

            {/* Background */}
            <Box
                borderRadius={"inherit"}
                position={"absolute"}
                inset={0}
                overflow={"hidden"}
                sx={{
                    maskImage:
                        "radial-gradient(circle at 60% 5%, black 0%, black 15%, transparent 60%)",
                    WebkitMaskImage:
                        "radial-gradient(circle at 60% 5%, black 0%, black 15%, transparent 60%)",
                }}
            >
                {/* Tiles */}
                <Box
                    opacity={hovered ? 1 : 0}
                    transition={"opacity 0.25s"}
                    transitionDelay={"0s"}
                >
                    {tilesStyles?.map((style, index) => (
                        <Tile
                            key={index}
                            top={style?.top}
                            left={style?.left}
                            height={style?.h}
                            width={style?.w}
                            sx={{ animationDelay: style?.delay }}
                        />
                    ))}
                </Box>

                {/* Lines */}
                {linesStyles?.map((style, index) => (
                    <Line
                        key={index}
                        hovered={hovered}
                        top={style?.top}
                        delay={index === 1 ? "0.15s" : "0.3s"}
                        left={style?.left}
                        right={style?.right}
                    />
                ))}
            </Box>
        </Box>
    );
}

export default DataPipelineCard;
