import { environment } from 'environments';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { ProcessingCategoryInput, ProcessingStatusInput } from 'models/Pipelines';


export const usePipelineAPIs = () => {
    const axiosPrivate = useAxiosPrivate();

    const formatDate = (date: Date) => {
        // Format date in local timezone
        const localDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const formattedDate = localDate.toLocaleDateString('en-CA'); // Returns YYYY-MM-DD format

        return formattedDate
    }

    const getUrl = (path: string) => `${environment.MOLECULELAKE_DATA_API}${path}`;
    // Pipelines
    const getAllPipelines = async () => {
        const response = await axiosPrivate.get(getUrl('/data_pipeline_admin/'));
        return response.data;
    };

    const getPipeline = async (slug: string | undefined) => {
        const response = await axiosPrivate.get(getUrl(`/data_pipeline_admin/${slug}/`));
        return response.data;
    };

    // Processing Categories
    const getProcessingCategories = async (pipelineSlug: string | undefined) => {
        const response = await axiosPrivate.get(getUrl(`/data_pipeline_admin/${pipelineSlug}/categories/`));
        return response.data;
    };

    const createProcessingCategory = async ({
        pipelineSlug,
        data
    }: {
        pipelineSlug: string;
        data: Partial<ProcessingCategoryInput>
    }) => {
        const response = await axiosPrivate.post(
            getUrl(`/data_pipeline_admin/${pipelineSlug}/categories/`),
            data
        );
        return response.data;
    };

    const getProcessingCategory = async ({
        pipelineSlug,
        categorySlug
    }: {
        pipelineSlug: string | undefined;
        categorySlug: string | undefined
    }) => {
        const response = await axiosPrivate.get(getUrl(
            `/data_pipeline_admin/${pipelineSlug}/categories/${categorySlug}/`)
        );
        return response.data;
    };

    // Processing Statuses
    const getCategoryProcessingStatuses = async ({
        pipelineSlug,
        categorySlug
    }: {
        pipelineSlug: string | undefined;
        categorySlug: string | undefined
    }) => {
        const response = await axiosPrivate.get(getUrl(
            `/data_pipeline_admin/${pipelineSlug}/categories/${categorySlug}/statuses/`)
        );
        return response.data;
    };

    const getAllDatapipelineStatuses = async ({ pipelineSlug, date }: {
        pipelineSlug: string | undefined;
        date: Date;
    }) => {
        const formattedDate = formatDate(date)
        const response = await axiosPrivate.get(
            getUrl(`/data_pipeline_admin/${pipelineSlug}/statuses/?date=${formattedDate}`)
        );
        return response.data;
    };

    const getCategoryStatus = async ({ pipelineSlug, categorySlug, date }: {
        pipelineSlug: string | undefined;
        categorySlug: string | undefined;
        date: Date;
    }) => {
        const formattedDate = formatDate(date)
        const response = await axiosPrivate.get(
            getUrl(`/data_pipeline_admin/${pipelineSlug}/categories/${categorySlug}/status/?date=${formattedDate}`)
        );
        return response.data;
    };

    const createProcessingStatus = async ({
        pipelineSlug,
        categorySlug,
        data
    }: {
        pipelineSlug: string;
        categorySlug: string;
        data: Partial<ProcessingStatusInput>
    }) => {
        const response = await axiosPrivate.post(
            getUrl(`/data_pipeline_admin/${pipelineSlug}/categories/${categorySlug}/statuses/`),
            data
        );
        return response.data;
    };

    const getProcessingStatus = async ({
        pipelineSlug,
        categorySlug,
        statusId
    }: {
        pipelineSlug: string;
        categorySlug: string;
        statusId: string
    }) => {
        const response = await axiosPrivate.get(getUrl(
            `/data_pipeline_admin/${pipelineSlug}/categories/${categorySlug}/statuses/${statusId}/`)
        );
        return response.data;
    };

    const updateProcessingStatus = async ({
        pipelineSlug,
        categorySlug,
        statusId,
        data
    }: {
        pipelineSlug: string;
        categorySlug: string;
        statusId: string;
        data: Partial<ProcessingStatusInput>
    }) => {
        const response = await axiosPrivate.put(
            getUrl(`/data_pipeline_admin/${pipelineSlug}/categories/${categorySlug}/statuses/${statusId}/`),
            data
        );
        return response.data;
    };

    const deleteProcessingStatus = async ({
        pipelineSlug,
        categorySlug,
        statusId
    }: {
        pipelineSlug: string;
        categorySlug: string;
        statusId: string
    }) => {
        const response = await axiosPrivate.delete(
            getUrl(`/data_pipeline_admin/${pipelineSlug}/categories/${categorySlug}/statuses/${statusId}/`)
        );
        return response.data;
    };

    return {
        getAllPipelines,
        getPipeline,
        getProcessingCategories,
        createProcessingCategory,
        getProcessingCategory,
        getAllDatapipelineStatuses,
        getCategoryStatus,
        getCategoryProcessingStatuses,
        createProcessingStatus,
        getProcessingStatus,
        updateProcessingStatus,
        deleteProcessingStatus
    };
};