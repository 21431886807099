import { createContext, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Box } from '@chakra-ui/react';
import { errorHandler } from 'utils/helpers';
import { slugToName } from 'components/pipelines/helpers';
import { usePipelineAPIs } from 'api/Pipelines/usePipelineAPIs';
import Loading from 'components/ui/Loading';
import MainPanelError from 'components/ui/MainPanelError';
import { DataPipeline } from 'models/Pipelines';
import NoResultsFound from 'components/ui/NoResultsFound';

interface PipelineContextType {
    pipeline: DataPipeline | undefined;
    selectedDate: Date;
    setSelectedDate: React.Dispatch<React.SetStateAction<Date>>
}

const initialContextState: PipelineContextType = {
    pipeline: undefined,
    selectedDate: new Date(),
    setSelectedDate: () => { },
};

export const PipelineContext = createContext<PipelineContextType>(initialContextState);

function PipelineView() {

    // States
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());

    // Hooks
    const { getPipeline } = usePipelineAPIs()
    const { pipelineSlug } = useParams();

    const { isLoading, error, data: pipeline } = useQuery<DataPipeline>({
        queryKey: ["pipelines", pipelineSlug],
        queryFn: () => getPipeline(pipelineSlug),
        staleTime: 2 * 1000 * 60, // 2 minutes
        enabled: !!pipelineSlug,
        retry: 1
    });

    return (
        <Box>
            {error ? (
                <MainPanelError
                    errorMessage={errorHandler(error).message}
                />
            ) : isLoading ? (
                <Box w={'100%'} height={'100%'}>
                    <Loading message={`loading "${slugToName(pipelineSlug)}" data pipeline...`} />
                </Box>
            ) : (
                pipeline ? (
                    <PipelineContext.Provider value={{ pipeline, selectedDate, setSelectedDate }}>
                        <Outlet />
                    </PipelineContext.Provider>
                ) :
                    <NoResultsFound dataName='data pipeline' />
            )}
        </Box>
    )
}

export default PipelineView