import { Box, Button, Flex, Grid, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal, Text } from '@chakra-ui/react';
import { useState } from 'react';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import { PiCalendarBold } from 'react-icons/pi';

interface CustomDatePickerProps {
    selectedDate: Date;
    onChange: (date: Date) => void;
}

// const CustomDatePicker = ({ selectedDate, onChange }: CustomDatePickerProps) => {
//     const [isOpen, setIsOpen] = useState(false);
//     const handleDateChange = (date: Date | null) => {
//         if (date) {
//             onChange(date);
//             setIsOpen(false);
//         }
//     };
//     const formatDate = (date: Date | null) => {
//         if (!date) return 'Select Date';
//         return date.toLocaleDateString('en-US', {
//             year: 'numeric',
//             month: 'short',
//             day: 'numeric'
//         });
//     };

//     const CustomInput = ({ onClick }: { onClick?: () => void }) => (
//         <Button
//             onClick={onClick}
//             size="xs"
//             variant="outline"
//             colorScheme="blue"
//             leftIcon={<PiCalendarBold size={14} />}
//             bg="white"
//             _hover={{ bg: 'blue.50' }}
//         >
//             {formatDate(selectedDate)}
//         </Button>
//     );

//     return (
//         <Popover
//             isOpen={isOpen}
//             onClose={() => setIsOpen(false)}
//             placement="bottom-end"
//             strategy="fixed"
//         >
//             <PopoverTrigger>
//                 <Flex>
//                     <DatePicker
//                         selected={selectedDate}
//                         onChange={handleDateChange}
//                         customInput={<CustomInput />}
//                         dateFormat="MMM d, yyyy"
//                         popperPlacement="bottom-end"
//                         onCalendarOpen={() => setIsOpen(true)}
//                         onCalendarClose={() => setIsOpen(false)}
//                         // popperModifiers={[
//                         //     {
//                         //         name: 'offset',
//                         //         options: {
//                         //             offset: [0, 8]
//                         //         }
//                         //     }
//                         // ]}
//                         wrapperClassName="date-picker-wrapper"
//                         calendarClassName="custom-datepicker"
//                     />
//                 </Flex>
//             </PopoverTrigger>
//         </Popover >
//     );
// };


const CustomDatePicker = ({ selectedDate, onChange }: CustomDatePickerProps) => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [isOpen, setIsOpen] = useState(false);

    const getDaysInMonth = (date: any) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const firstDayOfMonth = new Date(year, month, 1).getDay();

        const days = [];
        for (let i = 0; i < firstDayOfMonth; i++) {
            days.push(null);
        }

        for (let i = 1; i <= daysInMonth; i++) {
            days.push(new Date(year, month, i));
        }

        return days;
    };

    const formatDate = (date: any) => {
        if (!date) return 'Select Date';
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    const isToday = (date: any) => {
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    };

    const isSelected = (date: any) => {
        return selectedDate &&
            date.getDate() === selectedDate.getDate() &&
            date.getMonth() === selectedDate.getMonth() &&
            date.getFullYear() === selectedDate.getFullYear();
    };

    const nextMonth = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1));
    };

    const prevMonth = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1));
    };

    return (
        <Popover
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            placement="bottom-end"
            strategy="fixed"
        >
            <PopoverTrigger>
                <Button
                    onClick={() => setIsOpen(!isOpen)}
                    size="xs"
                    variant="outline"
                    colorScheme="blue"
                    leftIcon={<PiCalendarBold size={14} />}
                    bg="white"
                    _hover={{ bg: 'blue.50' }}
                >
                    {formatDate(selectedDate)}
                </Button>
            </PopoverTrigger>
            <Portal>
                <PopoverContent
                    width="300px"
                    p={0}
                    borderRadius="md"
                    boxShadow="lg"
                    zIndex={1400}
                >
                    <PopoverBody p={4}>
                        <Flex direction="column" gap={2}>
                            <Flex justify="space-between" align="center" mb={2}>
                                <Button
                                    size="sm"
                                    variant="ghost"
                                    onClick={prevMonth}
                                    leftIcon={<FaChevronLeft size={14} />}
                                >
                                </Button>
                                <Text fontWeight="medium">
                                    {currentMonth.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
                                </Text>
                                <Button
                                    size="sm"
                                    variant="ghost"
                                    onClick={nextMonth}
                                    rightIcon={<FaChevronRight size={14} />}
                                >
                                </Button>
                            </Flex>
                            <Grid
                                templateColumns="repeat(7, 1fr)"
                                gap={1}
                            >
                                {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
                                    <Text
                                        key={day}
                                        textAlign="center"
                                        fontSize="xs"
                                        fontWeight="medium"
                                        color="gray.600"
                                        p={1}
                                    >
                                        {day}
                                    </Text>
                                ))}
                                {getDaysInMonth(currentMonth).map((date, index) => (
                                    <Box
                                        key={index}
                                        p={1}
                                        textAlign="center"
                                        cursor={date ? "pointer" : "default"}
                                        onClick={() => {
                                            if (date) {
                                                onChange(date);
                                                setIsOpen(false);
                                            }
                                        }}
                                        bg={date && isSelected(date) ? "blue.500" :
                                            date && isToday(date) ? "gray.100" : "transparent"}
                                        color={date && isSelected(date) ? "white" :
                                            date && isToday(date) ? "blue.500" : "gray.800"}
                                        borderRadius="md"
                                        _hover={{
                                            bg: date ? (isSelected(date) ? "blue.600" : "gray.100") : "transparent"
                                        }}
                                    >
                                        {date ? date.getDate() : ''}
                                    </Box>
                                ))}
                            </Grid>
                        </Flex>
                    </PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    );
};

export default CustomDatePicker;