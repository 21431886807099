import { ComponentType, lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Text } from '@chakra-ui/react'
import { BsCodeSlash } from 'react-icons/bs';


interface StatusBreakdownsProps {
    data: any;
}

interface PipelineComponents {
    [key: string]: ComponentType<any>;
}

interface PipelineBreakdowns {
    [key: string]: PipelineComponents;
}

// Specific pipeline breakdowns
const mrnaLakeProteinDb: PipelineComponents = {
    'embeddings': lazy(() => import('./mrna-lake-protein-db/Embeddings'))
    // Add more categories as needed

    // Generic component:
    // 'any-general': lazy(() => import('./mrna-lake-protein-db/Embeddings'))
};
const moleculelake: PipelineComponents = {
    'embeddings': lazy(() => import('./Generic'))
};

const mrnaLake: PipelineComponents = {};


// Map of pipeline slugs to their category components
const PIPELINE_BREAKDOWNS: PipelineBreakdowns = {
    'moleculelake': moleculelake,
    'mrna-lake': mrnaLake,
    'mrna-lake-protein-db': mrnaLakeProteinDb,
};

// Loading component
const LoadingFallback = () => (
    <Flex
        direction="column"
        align="center"
        justify="center"
        py={12}
        bg="gray.50"
        borderRadius="md"
        color="gray.600"
    >
        <Text>Loading...</Text>
    </Flex>
);

const StatusBreakdowns = ({ data }: StatusBreakdownsProps) => {
    const { pipelineSlug = '', categorySlug = '' } = useParams<{
        pipelineSlug: string;
        categorySlug: string;
    }>();

    // Get the specific components for this pipeline/category combination
    const SpecificComponents = PIPELINE_BREAKDOWNS[pipelineSlug]?.[categorySlug];

    // If we have specific components for this combination, use them
    if (SpecificComponents) {
        return (
            <Suspense fallback={<LoadingFallback />}>
                <SpecificComponents data={data} />
            </Suspense>
        );
    }

    // Show "no UI available" message
    return (
        <Flex
            direction={'column'}
            align={'center'}
            justify={'center'}
            py={12}
            bg={'gray.50'}
            borderRadius={'md'}
            color={'gray.600'}
        >
            <BsCodeSlash size={32} style={{ marginBottom: '1rem' }} />
            <Text fontSize={'lg'} fontWeight={'medium'}>
                Breakdowns UI Not Available
            </Text>
            <Text fontSize={'sm'} mt={2} textAlign={'center'} maxW={'md'}>
                The visualization for {categorySlug} breakdowns in {pipelineSlug} pipeline is currently under development
            </Text>
        </Flex>
    );
}

export default StatusBreakdowns