import { Flex, Image, Text } from "@chakra-ui/react";

import notFoundImg from "assets/notFound.png";

export default function PageNotFound() {
  return (
    <Flex
      h={"100vh"}
      w={"full"}
      direction={"column"}
      align={"center"}
      justify={"center"}
      gap={"40px"}
    >
      <Image src={notFoundImg} alt={"404"} maxW={"20rem"} objectFit={"cover"} />
      <Text fontSize={"14px"} color={"gray.500"}>
        It seems we could not find the page you were looking for
      </Text>
    </Flex>
  );
}
