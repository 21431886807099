import { Flex, Text } from '@chakra-ui/react'
import { BsInfoCircle } from 'react-icons/bs'

const CategoryTable = () => {
    return (
        <Flex
            direction={'column'}
            align={'center'}
            justify={'center'}
            py={8}
            color={'gray.600'}
        >
            <BsInfoCircle size={32} style={{ marginBottom: '1rem' }} />
            <Text fontSize={'lg'} fontWeight={'medium'}>
                Coming Soon: Category Statuses Graph
            </Text>
            <Text fontSize={'sm'} mt={2}>
                This feature is currently under development
            </Text>
        </Flex>
    )
}

export default CategoryTable