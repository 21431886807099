import { Box, Card, CardBody, Flex, Heading, Text } from '@chakra-ui/react';
import PipelineTable from 'components/pipelines/pipeline/PipelineTable';
import { useContext } from 'react';
import { formatDate } from 'utils/helpers';
import { PipelineContext } from 'views/pipelines/PipelineView';


function Pipeline() {
    const { pipeline } = useContext(PipelineContext);

    return (
        <Flex direction={"column"} gap={3}>
            <Card mb={6} variant="outline">
                <CardBody>
                    <Box>
                        <Heading size="md" mb={2}>
                            {pipeline?.description}
                        </Heading>
                        <Text fontSize={'xs'} color={'gray.600'} fontStyle={'italic'}>
                            Last updated: {formatDate(new Date(pipeline?.updated_at ?? ''))}
                        </Text>
                    </Box>
                </CardBody>
            </Card>
            <PipelineTable />
        </Flex>
    )
}

export default Pipeline