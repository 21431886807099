import { useContext, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Box, Flex, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react'
import { CustomScrollBar } from 'components/ui/CustomScrollBar'
import NoResultsFound from 'components/ui/NoResultsFound'
import CustomDatePicker from '../CustomDatePicker'
import { PipelineContext } from 'views/pipelines/PipelineView'
import { usePipelineAPIs } from 'api/Pipelines/usePipelineAPIs'
import { ProcessingCategory, ProcessingStatus } from 'models/Pipelines'


const PipelineTable = () => {

    const navigate = useNavigate()

    const { pipeline, selectedDate, setSelectedDate } = useContext(PipelineContext);
    const { getAllDatapipelineStatuses } = usePipelineAPIs()
    const { pipelineSlug } = useParams();

    const { isLoading, data: statuses } = useQuery<ProcessingStatus[]>({
        queryKey: [pipelineSlug, 'statuses', selectedDate],
        queryFn: () => getAllDatapipelineStatuses({
            pipelineSlug,
            date: selectedDate
        }),
        staleTime: 2 * 1000 * 60,
        enabled: !!pipelineSlug && !!selectedDate,
        retry: 1
    });

    // Simplified getHistoricalStatus since we now receive filtered data
    const getHistoricalStatus = useMemo(() => {
        if (!statuses) return {};

        // Create a map of category IDs to their status values
        return statuses.reduce((acc, status) => {
            acc[status.processing_category] = status.value;
            return acc;
        }, {} as Record<string, number>);
    }, [statuses]);


    if (!pipeline) return <></>

    const { categories } = pipeline

    return (
        <Box>
            <Flex justify="flex-end" mb={4}>
                <Flex gap={2} align={'center'} justify={'center'}>
                    <Text color="blue.600" fontSize={'sm'}>select a date</Text>
                    <CustomDatePicker
                        selectedDate={selectedDate}
                        onChange={setSelectedDate}
                    />
                </Flex>
            </Flex>
            <TableContainer style={{ position: "relative" }}>
                <CustomScrollBar
                    height={"100%"}
                    width={"100%"}
                    padding={"0"}
                >
                    <Table variant="simple" size="sm">
                        <Thead
                            // position={"sticky"}
                            top={0}
                            bg={"gray.100"}
                            boxShadow={"none"}
                        >
                            <Tr position={"relative"}>
                                <Th
                                    boxShadow={"none"}
                                    py={3}
                                    px={3}
                                    textAlign={"left"}
                                    border={"none"}
                                    color={'gray.500'}
                                    borderRight={"1px solid"}
                                    borderRightColor={"gray.200"}
                                    borderTopLeftRadius={"md"}
                                    w="25%"
                                >
                                    Name
                                </Th>
                                <Th
                                    boxShadow="none"
                                    py={3}
                                    px={3}
                                    textAlign="left"
                                    border="none"
                                    color="gray.500"
                                    borderRight="1px solid"
                                    borderRightColor="gray.200"
                                    w="25%"
                                >
                                    Target
                                </Th>
                                <Th
                                    boxShadow="none"
                                    py={3}
                                    px={3}
                                    textAlign="left"
                                    border="none"
                                    color="gray.500"
                                    borderRight="1px solid"
                                    borderRightColor="gray.200"
                                    w="25%"
                                >
                                    Current Status
                                </Th>
                                <Th
                                    boxShadow="none"
                                    py={3}
                                    px={3}
                                    textAlign="left"
                                    border="none"
                                    color="blue.600"
                                    bg="blue.50"
                                    w="25%"
                                    minW={'280px'}
                                    borderTopRightRadius="md"
                                >
                                    Historical Status
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody position={"relative"}>
                            {!categories?.length ? (
                                <Tr>
                                    <Td colSpan={4}>
                                        <Flex py={8} direction={"column"} alignItems={"center"}>
                                            <NoResultsFound dataName={'categories'} />
                                        </Flex>
                                    </Td>
                                </Tr>
                            ) : (
                                categories.map((category: ProcessingCategory) => (
                                    <Tr
                                        key={"table-row" + category.id}
                                        id="pipeline-row"
                                        boxShadow={"none"}
                                        bg="transparent"
                                        transition="background 0.3s ease"
                                        _hover={{
                                            cursor: "pointer",
                                            "& > td": { bg: "blue.50" },
                                        }}
                                        onClick={() => category.slug && navigate(category.slug)}
                                    >
                                        <Td
                                            py={4}
                                            px={3}
                                            borderColor={"gray.100"}
                                            bg={'primary.200'}
                                            isTruncated
                                        >
                                            <Tooltip
                                                label={category.description}
                                                bg={"gray.900"}
                                                color={"gray.100"}
                                                hasArrow
                                                py={2}
                                                px={3}
                                                m={2}
                                                maxW={["200px", "220px", "240px"]}
                                                borderRadius={"6px"}
                                                fontSize={"12px"}
                                                boxShadow={"none"}
                                                arrowSize={8}
                                            >
                                                <Text
                                                    textTransform={"capitalize"}
                                                    whiteSpace={"nowrap"}
                                                    overflow={"hidden"}
                                                    textOverflow={"ellipsis"}
                                                    fontSize={["13px", "14px", "14px"]}
                                                    lineHeight={1.5}
                                                    fontStyle={'italic'}
                                                    fontWeight={'400'}
                                                    color={"gray.700"}
                                                >
                                                    {category.name}
                                                </Text>
                                            </Tooltip>
                                        </Td>
                                        <Td
                                            py={4}
                                            px={4}
                                            isTruncated
                                            lineHeight={1.4}
                                            borderColor={"gray.100"}
                                        >
                                            {typeof category.target === 'number' ?
                                                <Text
                                                    textTransform={"capitalize"}
                                                    whiteSpace={"nowrap"}
                                                    overflow={"hidden"}
                                                    textOverflow={"ellipsis"}
                                                    fontSize={["14px", "15px", "15px"]}
                                                    lineHeight={1.4}
                                                    letterSpacing={.6}
                                                    fontStyle={'italic'}
                                                    fontWeight={'500'}
                                                    color={"gray.900"}
                                                >
                                                    {category.target.toLocaleString('en-US')}

                                                </Text> :
                                                <Text
                                                    fontSize={["13px", "14px", "14px"]}
                                                    fontStyle={'italic'}
                                                    fontWeight={'400'}
                                                    color={"gray.400"}
                                                >
                                                    not specified
                                                </Text>

                                            }
                                        </Td>
                                        <Td
                                            py={4}
                                            px={4}
                                            isTruncated
                                            lineHeight={1.4}
                                            borderColor="gray.100"
                                        >
                                            {typeof category.current_status === 'number' ?
                                                <Text
                                                    textTransform={"capitalize"}
                                                    whiteSpace={"nowrap"}
                                                    overflow={"hidden"}
                                                    textOverflow={"ellipsis"}
                                                    fontSize={["14px", "15px", "15px"]}
                                                    lineHeight={1.4}
                                                    letterSpacing={.6}
                                                    fontStyle={'italic'}
                                                    fontWeight={'500'}
                                                    color={"gray.900"}
                                                >
                                                    {category.current_status.toLocaleString('en-US')}

                                                </Text> :
                                                <Text
                                                    fontSize={["13px", "14px", "14px"]}
                                                    fontStyle={'italic'}
                                                    fontWeight={'400'}
                                                    color={"gray.400"}
                                                >
                                                    not specified
                                                </Text>

                                            }
                                        </Td>
                                        <Td
                                            py={4}
                                            px={4}
                                            isTruncated
                                            lineHeight={1.4}
                                            borderColor="blue.100"
                                            bg="blue.50"
                                            transition="all 0.2s"
                                        >
                                            {isLoading ?
                                                (
                                                    <Flex
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <Spinner
                                                            size="sm"
                                                            color="blue.500"
                                                            mr={2}
                                                        />
                                                        <Text
                                                            fontSize="sm"
                                                            color="blue.600"
                                                            fontStyle="italic"
                                                        >
                                                            Loading...
                                                        </Text>
                                                    </Flex>
                                                )
                                                : !selectedDate ? (
                                                    <Flex
                                                        align="center"
                                                        justify="center"
                                                        py={1}
                                                    >
                                                        <Text
                                                            fontSize="sm"
                                                            color="blue.600"
                                                            fontStyle="italic"
                                                        >
                                                            Select a date to view historical status
                                                        </Text>
                                                    </Flex>
                                                ) : getHistoricalStatus[category.id] === undefined ? (
                                                    <Flex
                                                        align="center"
                                                        justify="center"
                                                        bg="white"
                                                        py={1}
                                                        px={3}
                                                        borderRadius="md"
                                                        border="1px dashed"
                                                        borderColor="blue.200"
                                                    >
                                                        <Text
                                                            fontSize="sm"
                                                            color="blue.600"
                                                            fontStyle="italic"
                                                        >
                                                            No status for this date
                                                        </Text>
                                                    </Flex>
                                                ) : (
                                                    <Text
                                                        fontSize={["14px", "15px", "15px"]}
                                                        fontWeight="500"
                                                        color="blue.700"
                                                        textAlign="center"
                                                    >
                                                        {getHistoricalStatus[category.id].toLocaleString('en-US')}
                                                    </Text>
                                                )}
                                        </Td>
                                    </Tr>
                                ))
                            )}
                        </Tbody>
                    </Table>
                </CustomScrollBar>
            </TableContainer>
        </Box>
    )
}

export default PipelineTable